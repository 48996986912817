<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-2 col-2">
                                <v-select v-model="commodityModel" :items="commodityLists" item-value="commodity_id" item-text="commodity_desc" label="COMMODITY" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-lg-2 col-2">
                                <v-select v-model="brandModel" :items="brandLists" item-value="brand_id" item-text="brand_desc" label="BRAND" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-lg-2 col-2">
                                <v-select v-model="colorModel" :items="colorLists" item-value="color_id" item-text="color" label="COLOR" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-lg-2 col-2">
                                <v-select v-model="qualityModel" :items="qualityLists" item-value="quality_id" item-text="quality_id" label="QUALITY" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-lg-2 col-2">
                                <v-select v-model="widthModel" :items="widthLists" item-value="width" item-text="width" label="WIDTH" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-lg-2 col-2">
                                <v-select v-model="thicknessModel" :items="thicknessLists" item-value="thick" item-text="thick" label="THICKNESS" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                            </div>
                            <div class="col-lg-12 col-12 text-right">
                                <v-btn class="mt-3" color="primary" elevation="4" rounded small @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>                                                   
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardResult">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div class="card-header text-right"><v-btn class="mt-3 text-white" color="#0D47A1" elevation="2" small dense @click="exportToExcel()">Export Excel<v-icon right dark>mdi-arrow-down-bold-box</v-icon></v-btn></div>
                        <v-data-table :headers="resultHeaders" hide-default-header :items="itemLists" class="elevation-1 mytable" page-count="10" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:header="props" >
                                <thead class="">
                                    <tr>
                                        <td rowspan="2" class="text-center">THICK.</td>
                                        <td rowspan="2" class="text-center">WIDTH</td>
                                        <td colspan="5" class="text-center" style="background-color: #d6eeee;">G300</td>
                                        <td colspan="1" class="text-center" style="background-color: #f2d0a9;">G340</td>
                                        <td colspan="5" class="text-center" style="background-color: #ced3dc;">G550</td>
                                        <td rowspan="2" class="text-center" style="background-color: #ced3dc;">Totals</td>
                                    </tr>
                                    <tr>
                                        <td rowspan="" class="text-center" style="background-color: #d6eeee;">70</td>
                                        <td rowspan="" class="text-center" style="background-color: #d6eeee;">100</td>
                                        <td rowspan="" class="text-center" style="background-color: #d6eeee;">150</td>
                                        <td rowspan="" class="text-center" style="background-color: #d6eeee;">165</td>
                                        <td rowspan="" class="text-center" style="background-color: #d6eeee;">200</td>
                                        <td rowspan="" class="text-center" style="background-color: #f2d0a9;">70</td>
                                        <td rowspan="" class="text-center" style="background-color: #ced3dc;">70</td>
                                        <td rowspan="" class="text-center" style="background-color: #ced3dc;">100</td>
                                        <td rowspan="" class="text-center" style="background-color: #ced3dc;">150</td>
                                        <td rowspan="" class="text-center" style="background-color: #ced3dc;">165</td>
                                        <td rowspan="" class="text-center" style="background-color: #ced3dc;">200</td>
                                    </tr>
                                </thead>
                            </template>
                            <template slot="body.append">
                                <tr class="pink--text">
                                    <th class="">Totals</th>
                                    <th class=""></th>
                                    <th class="text-right">{{ formatNumber(sumField('G30070')) }}</th>
                                    <th class="text-right">{{ formatNumber(sumField('G300100')) }}</th>
                                    <th class="text-right">{{ formatNumber(sumField('G300150')) }}</th>
                                    <th class="text-right">{{ formatNumber(sumField('G300165')) }}</th>
                                    <th class="text-right">{{ formatNumber(sumField('G300200')) }}</th>
                                    <th class="text-right">{{ formatNumber(sumField('G340150')) }}</th>
                                    <th class="text-right">{{ formatNumber(sumField('G55070')) }}</th>
                                    <th class="text-right">{{ formatNumber(sumField('G550100')) }}</th>
                                    <th class="text-right">{{ formatNumber(sumField('G550150')) }}</th>
                                    <th class="text-right">{{ formatNumber(sumField('G550165')) }}</th>
                                    <th class="text-right">{{ formatNumber(sumField('G550200')) }}</th>
                                </tr>
                            </template>
                            <template v-slot:[`item.G30070`]="{ item }">
                                <span>{{ formatNumber(item.G30070) }}</span>
                            </template>
                            <template v-slot:[`item.G300100`]="{ item }">
                                <span>{{ formatNumber(item.G300100) }}</span>
                            </template>
                            <template v-slot:[`item.G300150`]="{ item }">
                                <span>{{ formatNumber(item.G300150) }}</span>
                            </template>
                            <template v-slot:[`item.G300165`]="{ item }">
                                <span>{{ formatNumber(item.G300165) }}</span>
                            </template>
                            <template v-slot:[`item.G300200`]="{ item }">
                                <span>{{ formatNumber(item.G300200) }}</span>
                            </template>
                            <template v-slot:[`item.G340150`]="{ item }">
                                <span>{{ formatNumber(item.G340150) }}</span>
                            </template>
                            <template v-slot:[`item.G55070`]="{ item }">
                                <span>{{ formatNumber(item.G55070) }}</span>
                            </template>
                            <template v-slot:[`item.G550100`]="{ item }">
                                <span>{{ formatNumber(item.G550100) }}</span>
                            </template>
                            <template v-slot:[`item.G550150`]="{ item }">
                                <span>{{ formatNumber(item.G550150) }}</span>
                            </template>
                            <template v-slot:[`item.G550165`]="{ item }">
                                <span>{{ formatNumber(item.G550165) }}</span>
                            </template>
                            <template v-slot:[`item.G550200`]="{ item }">
                                <span>{{ formatNumber(item.G550200) }}</span>
                            </template>
                            <template v-slot:[`item.totalKanan`]="{ item }">
                                <tr class="pink--text">
                                    <th class="text-right">{{ formatNumber(item.G30070 + item.G300100 + item.G300150 + item.G300165 + item.G300200 + item.G340150 + item.G55070 + item.G550100 + item.G550150 + item.G550165 + item.G550200) }}</th>
                                </tr>
                            </template>
                        </v-data-table>                                             
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

import exportFromJSON from "export-from-json"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'CRC, Coil & AZ',
                disabled: false,
                href: '#',
                },
                {
                text: 'Coil Availability',
                disabled: true,
                href: '#',
                },
            ],
            commodityModel: '',
            commodityLists: [],
            brandModel: '',
            brandLists: [],
            colorModel: '',
            colorLists: [],
            qualityModel: '',
            qualityLists: [],
            widthModel: '',
            widthLists: [],
            thicknessModel: '',
            thicknessLists: [],
            itemLists: [],
            resultHeaders: [
                { text: 'THICK.', value: 'thick', align: 'right', class:"mytable" },
                { text: 'WIDTH', value: 'width', align: 'right', class:"" },
                { text: '70', value: 'G30070', align: 'right', class:"clr1" },
                { text: '100', value: 'G300100', align: 'right', class:"clr1" },
                { text: '150', value: 'G300150', align: 'right', class:"clr1" },
                { text: '165', value: 'G300165', align: 'right', class:"clr1" },
                { text: '200', value: 'G300200', align: 'right', class:"clr1" },
                { text: '150', value: 'G340150', align: 'right', class:"clr2" },
                { text: '70', value: 'G55070', align: 'right', class:"clr3" },
                { text: '100', value: 'G550100', align: 'right', class:"clr3" },
                { text: '150', value: 'G550150', align: 'right', class:"clr3" },
                { text: '165', value: 'G550165', align: 'right', class:"clr3" },
                { text: '200', value: 'G550200', align: 'right', class:"clr3" },
                { text: '', value: 'totalKanan', align: 'right', class:"" }
            ],
            searchItem: '',
            loadingDatatable: false,
            exportToExcelLists: []
        }
    },
    async mounted(){
        
        $('.cardResult').hide()
        this.initialize()

    },
    methods:{
        
        initialize(){
            
            this.$store.dispatch('setOverlay', true)

            axios.get(`${process.env.VUE_APP_URL}/api/sr/CoilAvailability`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
                })
            .then(res => {
                
                this.$store.dispatch('setOverlay', false)
                this.commodityLists = res.data.commodity
                this.brandLists = res.data.brand
                this.colorLists = res.data.color
                this.qualityLists = res.data.quality
                this.widthLists = res.data.width
                this.thicknessLists = res.data.thickness

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err)

            })

            // this.getData()

        },

        async getData(){

            this.itemLists = []
            this.exportToExcelLists = []
            this.$store.dispatch('setOverlay', true)
            this.loadingDatatable = true

            await axios.post(`${process.env.VUE_APP_URL}/api/sr/CoilAvailability/getData`, { 
                    commodity: this.commodityModel ? this.commodityModel : '',
                    brand: this.brandModel ? this.brandModel : '',
                    color: this.colorModel ? this.colorModel : '',
                    quality: this.qualityModel ? this.qualityModel : '',
                    width: this.widthModel ? this.widthModel : '',
                    thick: this.thicknessModel ? this.thicknessModel : '',
            },{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                $('.cardResult').show()
                this.$store.dispatch('setOverlay', false)
                this.loadingDatatable = false

                if( res.data.result.length != 0 ) {
                    this.itemLists = res.data.result
                    this.exportToExcelLists = res.data.result
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        icon: 'warning',
                        text: 'Data not found !'
                    })
                }
                    

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err)

            })

        },

        sumField(key) {
            // sum data in give key (property)
            return this.itemLists.reduce((a, b) => a + (b[key] || 0), 0)
        },

        exportToExcel(){

            const data = this.exportToExcelLists
            const fileName = "Coil-Availability-Excel-Data"
            const exportType = exportFromJSON.types.csv

            if (data) exportFromJSON({ data, fileName, exportType })

            Swal.fire({
                title: 'Success',
                text: 'Data exported successfully, check your download folder.'
            })

        },

        formatNumber(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

    },
    watch: {

        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

    .clr1 {
        background: #d6eeee;
    }

    .clr2 {
        background: #f2d0a9;
    }

    .clr3 {
        background: #ced3dc;
    }

    table, td {
        border: 1px solid black;
    }

</style>